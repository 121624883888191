import React from 'react';
import {Form, Formik} from 'formik';
import styled from 'styled-components';
import * as Yup from 'yup';
import {getQueryParams} from '../../util/query-string';
import WebuildbotsApi from '../../service/webuildbots-api';
import {
  FormField,
  FormFieldDataTypes,
  FormFieldTypes,
  FieldTypes,
} from '../../interface/form';
import TextField from './TextField';
import FormButton from './FormButton';
import SelectField from './SelectField';
import { CANCEL_VARIATIONS, SUBMIT_VARIATIONS } from 'src/const/buttons-enums';

const initValidationSchema = (fields: FormField[]) => {
  const schema = {};
  fields.forEach((field) => {
    if (field.validations.length) {
      field.validations.forEach((validation) => {
        const yupValidation = Yup.string().matches(
          new RegExp(validation.regex),
          validation.failureText[field.language || "en"],
        );
        schema[field.key] = field.optional
          ? yupValidation.notRequired()
          : yupValidation.required();
      });
    } else {
      schema[field.key] = field.optional
        ? Yup.string().notRequired()
        : Yup.string().required();
    }
  });
  return Yup.object().shape(schema);
};

const initFormValues = (fields: FormField[]): Record<string, any> => {
  const initFields: Record<string, any> = {};
  fields.forEach((field) => {
    initFields[field.key] = '';
  });
  return initFields;
};

const FormExtension: React.FC = () => {
  const queryParams = getQueryParams();
  const formId = queryParams.formId;
  const fields: FormField[] = JSON.parse(queryParams.fields);
  const {
    token,
    onSuccessBlockId,
    onCancelBlockId,
    channel,
    client,
  } = queryParams;

  let lang = "en";

  const api = React.useMemo(
    () => new WebuildbotsApi(token, client[0], channel),
    [token, channel, client],
  );

  const handleSubmit = async (values: Record<string, any>) => {
    fields.forEach((field) => {
      if ([undefined, ''].includes(values[field.key])) {
        field.value = null;
      } else {
        field.value = values[field.key];
      }
    });
    const formArgs = fields.reduce((obj, field) => {
      obj[field.key] = field.value;
      return obj;
    }, {});
    await api.setFormFields(fields, formId);
    await api.closeExtension(onSuccessBlockId, formArgs);
  };

  const handleCancel = async () => {
    await api.closeExtension(onCancelBlockId);
  };

  const getFieldType = (field: FormField): FieldTypes => {
    const {secret, fieldType, dataType} = field;
    if (secret) {
      return FieldTypes.PASSWORD;
    }

    if (dataType === FormFieldDataTypes.NUMBER) {
      return FieldTypes.NUMBER;
    }

    switch (fieldType) {
      case FormFieldTypes.INPUT_EMAIL:
        return FieldTypes.EMAIL;
      case FormFieldTypes.SELECT:
        return FieldTypes.SELECT;
      case FormFieldTypes.OPEN_SELECT:
        return FieldTypes.SELECT;
      case FormFieldTypes.INPUT_DATE:
        return FieldTypes.DATE;
      default:
        return FieldTypes.TEXT;
    }
  };

  const isSelectType = (fieldType: FieldTypes): boolean => {
    return [FormFieldTypes.OPEN_SELECT, FormFieldTypes.SELECT].includes(
      (fieldType as unknown) as FormFieldTypes,
    );
  };

  return (
    <Formik
      initialValues={initFormValues(fields)}
      onSubmit={handleSubmit}
      validationSchema={initValidationSchema(fields)}
    >
      {({errors, touched}) => (
        <Form>
          {fields.map((field, index) => {
            const fieldType = getFieldType(field);
            lang = field.language || "en";
            // If no question then use title
            const {question} = field;
            const label = question ? question![lang] || question!["en"] || question[Object.keys(question)[0]] : field.title;
            if (isSelectType(fieldType) && field.choices) {
              return (
                <SelectField
                  key={index}
                  name={field.key}
                  type={fieldType}
                  choices={field.choices}
                  label={label}
                  errors={errors}
                  touched={touched}
                />
              );
            }
            return (
              <TextField
                key={index}
                name={field.key}
                type={fieldType}
                label={label}
                errors={errors}
                touched={touched}
              />
            );
          })}
          <ButtonWrapper>
            <FormButton$ type={'submit'} value={SUBMIT_VARIATIONS[lang.toLocaleUpperCase()]} />
            <FormButton$
              onClick={handleCancel}
              type={'button'}
              value={CANCEL_VARIATIONS[lang.toLocaleUpperCase()]}
            />
          </ButtonWrapper>
        </Form>
      )}
    </Formik>
  );
};

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const FormButton$ = styled(FormButton)`
  border-color: inherit !important;
  color: inherit !important;
`;

export default FormExtension;
